export default `iso alpha2	iso alpha3	iso numeric	fips code	name	capital	areaInSqKm	population	continent	languages	currency	geonameId
AD	AND	20	AN	Andorra	Andorra la Vella	468.0	77006	EU	ca	EUR	3041565
AE	ARE	784	AE	United Arab Emirates	Abu Dhabi	82880.0	9630959	AS	"ar-AE,fa,en,hi,ur"	AED	290557
AF	AFG	4	AF	Afghanistan	Kabul	647500.0	37172386	AS	"fa-AF,ps,uz-AF,tk"	AFN	1149361
AG	ATG	28	AC	Antigua and Barbuda	St John's	443.0	96286	NA	en-AG	XCD	3576396
AI	AIA	660	AV	Anguilla	The Valley	102.0	13254	NA	en-AI	XCD	3573511
AL	ALB	8	AL	Albania	Tirana	28748.0	2866376	EU	"sq,el"	ALL	783754
AM	ARM	51	AM	Armenia	Yerevan	29800.0	2951776	AS	hy	AMD	174982
AO	AGO	24	AO	Angola	Luanda	1246700.0	30809762	AF	pt-AO	AOA	3351879
AQ	ATA	10	AY	Antarctica		1.4E+07	0	AN			6697173
AR	ARG	32	AR	Argentina	Buenos Aires	2766890.0	44494502	SA	"es-AR,en,it,de,fr,gn"	ARS	3865483
AS	ASM	16	AQ	American Samoa	Pago Pago	199.0	55465	OC	"en-AS,sm,to"	USD	5880801
AT	AUT	40	AU	Austria	Vienna	83858.0	8847037	EU	"de-AT,hr,hu,sl"	EUR	2782113
AU	AUS	36	AS	Australia	Canberra	7686850.0	24992369	OC	en-AU	AUD	2077456
AW	ABW	533	AA	Aruba	Oranjestad	193.0	105845	NA	"nl-AW,pap,es,en"	AWG	3577279
AZ	AZE	31	AJ	Azerbaijan	Baku	86600.0	9942334	AS	"az,ru,hy"	AZN	587116
BA	BIH	70	BK	Bosnia and Herzegovina	Sarajevo	51129.0	3323929	EU	"bs,hr-BA,sr-BA"	BAM	3277605
BB	BRB	52	BB	Barbados	Bridgetown	431.0	286641	NA	en-BB	BBD	3374084
BD	BGD	50	BG	Bangladesh	Dhaka	144000.0	161356039	AS	"bn-BD,en"	BDT	1210997
BE	BEL	56	BE	Belgium	Brussels	30510.0	11422068	EU	"nl-BE,fr-BE,de-BE"	EUR	2802361
BF	BFA	854	UV	Burkina Faso	Ouagadougou	274200.0	19751535	AF	"fr-BF,mos"	XOF	2361809
BG	BGR	100	BU	Bulgaria	Sofia	110910.0	7000039	EU	"bg,tr-BG,rom"	BGN	732800
BH	BHR	48	BA	Bahrain	Manama	665.0	1569439	AS	"ar-BH,en,fa,ur"	BHD	290291
BI	BDI	108	BY	Burundi	Gitega	27830.0	11175378	AF	"fr-BI,rn"	BIF	433561
BJ	BEN	204	BN	Benin	Porto-Novo	112620.0	11485048	AF	fr-BJ	XOF	2395170
BM	BMU	60	BD	Bermuda	Hamilton	53.0	63968	NA	"en-BM,pt"	BMD	3573345
BN	BRN	96	BX	Brunei	Bandar Seri Begawan	5770.0	428962	AS	"ms-BN,en-BN"	BND	1820814
BO	BOL	68	BL	Bolivia	Sucre	1098580.0	11353142	SA	"es-BO,qu,ay"	BOB	3923057
BR	BRA	76	BR	Brazil	Brasília	8511965.0	209469333	SA	"pt-BR,es,en,fr"	BRL	3469034
BS	BHS	44	BF	Bahamas	Nassau	13940.0	385640	NA	en-BS	BSD	3572887
BT	BTN	64	BT	Bhutan	Thimphu	47000.0	754394	AS	dz	BTN	1252634
BW	BWA	72	BC	Botswana	Gaborone	600370.0	2254126	AF	"en-BW,tn-BW"	BWP	933860
BY	BLR	112	BO	Belarus	Minsk	207600.0	9485386	EU	"be,ru"	BYN	630336
BZ	BLZ	84	BH	Belize	Belmopan	22966.0	383071	NA	"en-BZ,es"	BZD	3582678
CA	CAN	124	CA	Canada	Ottawa	9984670.0	37058856	NA	"en-CA,fr-CA,iu"	CAD	6251999
CC	CCK	166	CK	Cocos (Keeling) Islands	West Island	14.0	628	AS	"ms-CC,en"	AUD	1547376
CD	COD	180	CG	DR Congo	Kinshasa	2345410.0	84068091	AF	"fr-CD,ln,ktu,kg,sw,lua"	CDF	203312
CF	CAF	140	CT	Central African Republic	Bangui	622984.0	4666377	AF	"fr-CF,sg,ln,kg"	XAF	239880
CG	COG	178	CF	Congo Republic	Brazzaville	342000.0	5244363	AF	"fr-CG,kg,ln-CG"	XAF	2260494
CH	CHE	756	SZ	Switzerland	Bern	41290.0	8516543	EU	"de-CH,fr-CH,it-CH,rm"	CHF	2658434
CI	CIV	384	IV	Ivory Coast	Yamoussoukro	322460.0	25069229	AF	fr-CI	XOF	2287781
CK	COK	184	CW	Cook Islands	Avarua	240.0	21388	OC	"en-CK,mi"	NZD	1899402
CL	CHL	152	CI	Chile	Santiago	756950.0	18729160	SA	es-CL	CLP	3895114
CM	CMR	120	CM	Cameroon	Yaoundé	475440.0	25216237	AF	"en-CM,fr-CM"	XAF	2233387
CN	CHN	156	CH	China	Beijing	9596960.0	1411778724	AS	"zh-CN,yue,wuu,dta,ug,za"	CNY	1814991
CO	COL	170	CO	Colombia	Bogotá	1138910.0	49648685	SA	es-CO	COP	3686110
CR	CRI	188	CS	Costa Rica	San José	51100.0	4999441	NA	"es-CR,en"	CRC	3624060
CU	CUB	192	CU	Cuba	Havana	110860.0	11338138	NA	"es-CU,pap"	CUP	3562981
CV	CPV	132	CV	Cabo Verde	Praia	4033.0	543767	AF	pt-CV	CVE	3374766
CW	CUW	531	UC	Curaçao	Willemstad	444.0	159849	NA	"nl,pap"	ANG	7626836
CX	CXR	162	KT	Christmas Island	Flying Fish Cove	135.0	1500	OC	"en,zh,ms-CX"	AUD	2078138
CY	CYP	196	CY	Cyprus	Nicosia	9250.0	1189265	EU	"el-CY,tr-CY,en"	EUR	146669
CZ	CZE	203	EZ	Czechia	Prague	78866.0	10625695	EU	"cs,sk"	CZK	3077311
DE	DEU	276	GM	Germany	Berlin	357021.0	82927922	EU	de	EUR	2921044
DJ	DJI	262	DJ	Djibouti	Djibouti	23000.0	958920	AF	"fr-DJ,ar,so-DJ,aa"	DJF	223816
DK	DNK	208	DA	Denmark	Copenhagen	43094.0	5797446	EU	"da-DK,en,fo,de-DK"	DKK	2623032
DM	DMA	212	DO	Dominica	Roseau	754.0	71625	NA	en-DM	XCD	3575830
DO	DOM	214	DR	Dominican Republic	Santo Domingo	48730.0	10627165	NA	es-DO	DOP	3508796
DZ	DZA	12	AG	Algeria	Algiers	2381740.0	42228429	AF	ar-DZ	DZD	2589581
EC	ECU	218	EC	Ecuador	Quito	283560.0	17084357	SA	es-EC	USD	3658394
EE	EST	233	EN	Estonia	Tallinn	45226.0	1320884	EU	"et,ru"	EUR	453733
EG	EGY	818	EG	Egypt	Cairo	1001450.0	98423595	AF	"ar-EG,en,fr"	EGP	357994
EH	ESH	732	WI	Western Sahara		266000.0	273008	AF	"ar,mey"	MAD	2461445
ER	ERI	232	ER	Eritrea	Asmara	121320.0	6209262	AF	"aa-ER,ar,tig,kun,ti-ER"	ERN	338010
ES	ESP	724	SP	Spain	Madrid	504782.0	46723749	EU	"es-ES,ca,gl,eu,oc"	EUR	2510769
ET	ETH	231	ET	Ethiopia	Addis Ababa	1127127.0	109224559	AF	"am,en-ET,om-ET,ti-ET,so-ET,sid"	ETB	337996
FI	FIN	246	FI	Finland	Helsinki	337030.0	5518050	EU	"fi-FI,sv-FI,smn"	EUR	660013
FJ	FJI	242	FJ	Fiji	Suva	18270.0	883483	OC	"en-FJ,fj"	FJD	2205218
FK	FLK	238	FK	Falkland Islands	Stanley	12173.0	2638	SA	en-FK	FKP	3474414
FM	FSM	583	FM	Micronesia	Palikir	702.0	112640	OC	"en-FM,chk,pon,yap,kos,uli,woe,nkr,kpg"	USD	2081918
FO	FRO	234	FO	Faroe Islands	Tórshavn	1399.0	48497	EU	"fo,da-FO"	DKK	2622320
FR	FRA	250	FR	France	Paris	547030.0	66987244	EU	"fr-FR,frp,br,co,ca,eu,oc"	EUR	3017382
GA	GAB	266	GB	Gabon	Libreville	267667.0	2119275	AF	fr-GA	XAF	2400553
GB	GBR	826	UK	United Kingdom	London	244820.0	66488991	EU	"en-GB,cy-GB,gd"	GBP	2635167
GD	GRD	308	GJ	Grenada	St. George's	344.0	111454	NA	en-GD	XCD	3580239
GE	GEO	268	GG	Georgia	Tbilisi	69700.0	3731000	AS	"ka,ru,hy,az"	GEL	614540
GF	GUF	254	FG	French Guiana	Cayenne	91000.0	195506	SA	fr-GF	EUR	3381670
GG	GGY	831	GK	Guernsey	St Peter Port	78.0	65228	EU	"en,nrf"	GBP	3042362
GH	GHA	288	GH	Ghana	Accra	239460.0	29767108	AF	"en-GH,ak,ee,tw"	GHS	2300660
GI	GIB	292	GI	Gibraltar	Gibraltar	6.5	33718	EU	"en-GI,es,it,pt"	GIP	2411586
GL	GRL	304	GL	Greenland	Nuuk	2166086.0	56025	NA	"kl,da-GL,en"	DKK	3425505
GM	GMB	270	GA	The Gambia	Banjul	11300.0	2280102	AF	"en-GM,mnk,wof,wo,ff"	GMD	2413451
GN	GIN	324	GV	Guinea	Conakry	245857.0	12414318	AF	fr-GN	GNF	2420477
GP	GLP	312	GP	Guadeloupe	Basse-Terre	1780.0	443000	NA	fr-GP	EUR	3579143
GQ	GNQ	226	EK	Equatorial Guinea	Malabo	28051.0	1308974	AF	"es-GQ,fr,pt"	XAF	2309096
GR	GRC	300	GR	Greece	Athens	131940.0	10727668	EU	"el-GR,en,fr"	EUR	390903
GT	GTM	320	GT	Guatemala	Guatemala City	108890.0	17247807	NA	es-GT	GTQ	3595528
GU	GUM	316	GQ	Guam	Hagåtña	549.0	165768	OC	"en-GU,ch-GU"	USD	4043988
GW	GNB	624	PU	Guinea-Bissau	Bissau	36120.0	1874309	AF	"pt-GW,pov"	XOF	2372248
GY	GUY	328	GY	Guyana	Georgetown	214970.0	779004	SA	en-GY	GYD	3378535
HK	HKG	344	HK	Hong Kong	Hong Kong	1092.0	7451000	AS	"zh-HK,yue,zh,en"	HKD	1819730
HN	HND	340	HO	Honduras	Tegucigalpa	112090.0	9587522	NA	"es-HN,cab,miq"	HNL	3608932
HR	HRV	191	HR	Croatia	Zagreb	56542.0	3871833	EU	"hr-HR,sr"	EUR	3202326
HT	HTI	332	HA	Haiti	Port-au-Prince	27750.0	11123176	NA	"ht,fr-HT"	HTG	3723988
HU	HUN	348	HU	Hungary	Budapest	93030.0	9768785	EU	hu-HU	HUF	719819
ID	IDN	360	ID	Indonesia	Jakarta	1919440.0	267663435	AS	"id,en,nl,jv"	IDR	1643084
IE	IRL	372	EI	Ireland	Dublin	70280.0	4853506	EU	"en-IE,ga-IE"	EUR	2963597
IL	ISR	376	IS	Israel		20770.0	8883800	AS	"he,ar-IL,en-IL,"	ILS	294640
IN	IND	356	IN	India	New Delhi	3287590.0	1352617328	AS	"en-IN,hi,bn,te,mr,ta,ur,gu,kn,ml,or,pa,as,bh,sat,ks,ne,sd,kok,doi,mni,sit,sa,fr,lus,inc"	INR	1269750
IO	IOT	86	IO	British Indian Ocean Territory		60.0	4000	AS	en-IO	USD	1282588
IQ	IRQ	368	IZ	Iraq	Baghdad	437072.0	38433600	AS	"ar-IQ,ku,hy"	IQD	99237
IR	IRN	364	IR	Iran	Tehran	1648000.0	81800269	AS	"fa-IR,ku"	IRR	130758
IS	ISL	352	IC	Iceland	Reykjavik	103000.0	353574	EU	"is,en,de,da,sv,no"	ISK	2629691
IT	ITA	380	IT	Italy	Rome	301230.0	60431283	EU	"it-IT,de-IT,fr-IT,sc,ca,co,sl"	EUR	3175395
JE	JEY	832	JE	Jersey	Saint Helier	116.0	90812	EU	"en,fr,nrf"	GBP	3042142
JM	JAM	388	JM	Jamaica	Kingston	10991.0	2934855	NA	en-JM	JMD	3489940
JO	JOR	400	JO	Jordan	Amman	92300.0	9956011	AS	"ar-JO,en"	JOD	248816
JP	JPN	392	JA	Japan	Tokyo	377835.0	126529100	AS	ja	JPY	1861060
KE	KEN	404	KE	Kenya	Nairobi	582650.0	51393010	AF	"en-KE,sw-KE"	KES	192950
KG	KGZ	417	KG	Kyrgyzstan	Bishkek	198500.0	6315800	AS	"ky,uz,ru"	KGS	1527747
KH	KHM	116	CB	Cambodia	Phnom Penh	181040.0	16249798	AS	"km,fr,en"	KHR	1831722
KI	KIR	296	KR	Kiribati	Tarawa	811.0	115847	OC	"en-KI,gil"	AUD	4030945
KM	COM	174	CN	Comoros	Moroni	2170.0	832322	AF	"ar,fr-KM"	KMF	921929
KN	KNA	659	SC	St Kitts and Nevis	Basseterre	261.0	52441	NA	en-KN	XCD	3575174
KP	PRK	408	KN	North Korea	Pyongyang	120540.0	25549819	AS	ko-KP	KPW	1873107
KR	KOR	410	KS	South Korea	Seoul	98480.0	51635256	AS	"ko-KR,en"	KRW	1835841
KW	KWT	414	KU	Kuwait	Kuwait City	17820.0	4137309	AS	"ar-KW,en"	KWD	285570
KY	CYM	136	CJ	Cayman Islands	George Town	262.0	64174	NA	en-KY	KYD	3580718
KZ	KAZ	398	KZ	Kazakhstan	Nur-Sultan	2717300.0	18276499	AS	"kk,ru"	KZT	1522867
LA	LAO	418	LA	Laos	Vientiane	236800.0	7061507	AS	"lo,fr,en"	LAK	1655842
LB	LBN	422	LE	Lebanon	Beirut	10400.0	6848925	AS	"ar-LB,fr-LB,en,hy"	LBP	272103
LC	LCA	662	ST	Saint Lucia	Castries	616.0	181889	NA	en-LC	XCD	3576468
LI	LIE	438	LS	Liechtenstein	Vaduz	160.0	37910	EU	de-LI	CHF	3042058
LK	LKA	144	CE	Sri Lanka	Colombo	65610.0	21670000	AS	"si,ta,en"	LKR	1227603
LR	LBR	430	LI	Liberia	Monrovia	111370.0	4818977	AF	en-LR	LRD	2275384
LS	LSO	426	LT	Lesotho	Maseru	30355.0	2108132	AF	"en-LS,st,zu,xh"	LSL	932692
LT	LTU	440	LH	Lithuania	Vilnius	65200.0	2789533	EU	"lt,ru,pl"	EUR	597427
LU	LUX	442	LU	Luxembourg	Luxembourg	2586.0	607728	EU	"lb,de-LU,fr-LU"	EUR	2960313
LV	LVA	428	LG	Latvia	Riga	64589.0	1926542	EU	"lv,ru,lt"	EUR	458258
LY	LBY	434	LY	Libya	Tripoli	1759540.0	6678567	AF	"ar-LY,it,en"	LYD	2215636
MA	MAR	504	MO	Morocco	Rabat	446550.0	36029138	AF	"ar-MA,ber,fr"	MAD	2542007
MC	MCO	492	MN	Monaco	Monaco	1.95	38682	EU	"fr-MC,en,it"	EUR	2993457
MD	MDA	498	MD	Moldova	Chișinău	33843.0	3545883	EU	"ro,ru,gag,tr"	MDL	617790
ME	MNE	499	MJ	Montenegro	Podgorica	14026.0	622345	EU	"sr,hu,bs,sq,hr,rom"	EUR	3194884
MF	MAF	663	RN	Saint Martin	Marigot	53.0	37264	NA	fr	EUR	3578421
MG	MDG	450	MA	Madagascar	Antananarivo	587040.0	26262368	AF	"fr-MG,mg"	MGA	1062947
MH	MHL	584	RM	Marshall Islands	Majuro	181.3	58413	OC	"mh,en-MH"	USD	2080185
MK	MKD	807	MK	North Macedonia	Skopje	25333.0	2082958	EU	"mk,sq,tr,rmm,sr"	MKD	718075
ML	MLI	466	ML	Mali	Bamako	1240000.0	19077690	AF	"fr-ML,bm"	XOF	2453866
MM	MMR	104	BM	Myanmar	Nay Pyi Taw	678500.0	53708395	AS	my	MMK	1327865
MN	MNG	496	MG	Mongolia	Ulaanbaatar	1565000.0	3170208	AS	"mn,ru"	MNT	2029969
MO	MAC	446	MC	Macao	Macao	254.0	631636	AS	"zh,zh-MO,pt"	MOP	1821275
MP	MNP	580	CQ	Northern Mariana Islands	Saipan	477.0	56882	OC	"fil,tl,zh,ch-MP,en-MP"	USD	4041468
MQ	MTQ	474	MB	Martinique	Fort-de-France	1100.0	432900	NA	fr-MQ	EUR	3570311
MR	MRT	478	MR	Mauritania	Nouakchott	1030700.0	4403319	AF	"ar-MR,fuc,snk,fr,mey,wo"	MRU	2378080
MS	MSR	500	MH	Montserrat	Plymouth	102.0	9341	NA	en-MS	XCD	3578097
MT	MLT	470	MT	Malta	Valletta	316.0	483530	EU	"mt,en-MT"	EUR	2562770
MU	MUS	480	MP	Mauritius	Port Louis	2040.0	1265303	AF	"en-MU,bho,fr"	MUR	934292
MV	MDV	462	MV	Maldives	Malé	300.0	515696	AS	"dv,en"	MVR	1282028
MW	MWI	454	MI	Malawi	Lilongwe	118480.0	17563749	AF	"ny,yao,tum,swk"	MWK	927384
MX	MEX	484	MX	Mexico	Mexico City	1972550.0	126190788	NA	es-MX	MXN	3996063
MY	MYS	458	MY	Malaysia	Kuala Lumpur	329750.0	31528585	AS	"ms-MY,en,zh,ta,te,ml,pa,th"	MYR	1733045
MZ	MOZ	508	MZ	Mozambique	Maputo	801590.0	29495962	AF	"pt-MZ,vmw"	MZN	1036973
NA	NAM	516	WA	Namibia	Windhoek	825418.0	2448255	AF	"en-NA,af,de,hz,naq"	NAD	3355338
NC	NCL	540	NC	New Caledonia	Noumea	19060.0	284060	OC	fr-NC	XPF	2139685
NE	NER	562	NG	Niger	Niamey	1267000.0	22442948	AF	"fr-NE,ha,kr,dje"	XOF	2440476
NF	NFK	574	NF	Norfolk Island	Kingston	34.6	1828	OC	en-NF	AUD	2155115
NG	NGA	566	NI	Nigeria	Abuja	923768.0	195874740	AF	"en-NG,ha,yo,ig,ff"	NGN	2328926
NI	NIC	558	NU	Nicaragua	Managua	129494.0	6465513	NA	"es-NI,en"	NIO	3617476
NL	NLD	528	NL	Netherlands	Amsterdam	41526.0	17231017	EU	"nl-NL,fy-NL"	EUR	2750405
NO	NOR	578	NO	Norway	Oslo	324220.0	5314336	EU	"no,nb,nn,se,fi"	NOK	3144096
NP	NPL	524	NP	Nepal	Kathmandu	140800.0	28087871	AS	"ne,en"	NPR	1282988
NR	NRU	520	NR	Nauru	Yaren District	21.0	12704	OC	"na,en-NR"	AUD	2110425
NU	NIU	570	NE	Niue	Alofi	260.0	2166	OC	"niu,en-NU"	NZD	4036232
NZ	NZL	554	NZ	New Zealand	Wellington	268680.0	4885500	OC	"en-NZ,mi"	NZD	2186224
OM	OMN	512	MU	Oman	Muscat	212460.0	4829483	AS	"ar-OM,en,bal,ur"	OMR	286963
PA	PAN	591	PM	Panama	Panama City	78200.0	4176873	NA	"es-PA,en"	PAB	3703430
PE	PER	604	PE	Peru	Lima	1285220.0	31989256	SA	"es-PE,qu,ay"	PEN	3932488
PF	PYF	258	FP	French Polynesia	Papeete	4167.0	277679	OC	"fr-PF,ty"	XPF	4030656
PG	PNG	598	PP	Papua New Guinea	Port Moresby	462840.0	8606316	OC	"en-PG,ho,meu,tpi"	PGK	2088628
PH	PHL	608	RP	Philippines	Manila	300000.0	106651922	AS	"tl,en-PH,fil,ceb,ilo,hil,war,pam,bik,bcl,pag,mrw,tsg,mdh,cbk,krj,sgd,msb,akl,ibg,yka,mta,abx"	PHP	1694008
PK	PAK	586	PK	Pakistan	Islamabad	803940.0	212215030	AS	"ur-PK,en-PK,pa,sd,ps,brh"	PKR	1168579
PL	POL	616	PL	Poland	Warsaw	312685.0	37978548	EU	pl	PLN	798544
PM	SPM	666	SB	Saint Pierre and Miquelon	Saint-Pierre	242.0	7012	NA	fr-PM	EUR	3424932
PN	PCN	612	PC	Pitcairn Islands	Adamstown	47.0	46	OC	en-PN	NZD	4030699
PR	PRI	630	RQ	Puerto Rico	San Juan	9104.0	3195153	NA	"en-PR,es-PR"	USD	4566966
PS	PSE	275	WE	Palestine		5970.0	4569087	AS	ar-PS	ILS	6254930
PT	PRT	620	PO	Portugal	Lisbon	92391.0	10281762	EU	"pt-PT,mwl"	EUR	2264397
PW	PLW	585	PS	Palau	Ngerulmud	458.0	17907	OC	"pau,sov,en-PW,tox,ja,fil,zh"	USD	1559582
PY	PRY	600	PA	Paraguay	Asunción	406750.0	6956071	SA	"es-PY,gn"	PYG	3437598
QA	QAT	634	QA	Qatar	Doha	11437.0	2781677	AS	"ar-QA,es"	QAR	289688
RE	REU	638	RE	Réunion	Saint-Denis	2517.0	776948	AF	fr-RE	EUR	935317
RO	ROU	642	RO	Romania	Bucharest	237500.0	19473936	EU	"ro,hu,rom"	RON	798549
RS	SRB	688	RI	Serbia	Belgrade	88361.0	6982084	EU	"sr,hu,bs,rom"	RSD	6290252
RU	RUS	643	RS	Russia	Moscow	1.71E+07	144478050	EU	"ru,tt,xal,cau,ady,kv,ce,tyv,cv,udm,tut,mns,bua,myv,mdf,chm,ba,inh,kbd,krc,av,sah,nog"	RUB	2017370
RW	RWA	646	RW	Rwanda	Kigali	26338.0	12301939	AF	"rw,en-RW,fr-RW,sw"	RWF	49518
SA	SAU	682	SA	Saudi Arabia	Riyadh	1960582.0	33699947	AS	ar-SA	SAR	102358
SB	SLB	90	BP	Solomon Islands	Honiara	28450.0	652858	OC	"en-SB,tpi"	SBD	2103350
SC	SYC	690	SE	Seychelles	Victoria	455.0	96762	AF	"en-SC,fr-SC"	SCR	241170
SD	SDN	729	SU	Sudan	Khartoum	1861484.0	41801533	AF	"ar-SD,en,fia"	SDG	366755
SE	SWE	752	SW	Sweden	Stockholm	449964.0	10183175	EU	"sv-SE,se,sma,fi-SE"	SEK	2661886
SG	SGP	702	SN	Singapore	Singapore	692.7	5638676	AS	"cmn,en-SG,ms-SG,ta-SG,zh-SG"	SGD	1880251
SI	SVN	705	SI	Slovenia	Ljubljana	20273.0	2067372	EU	"sl,sh"	EUR	3190538
SJ	SJM	744	SV	Svalbard and Jan Mayen	Longyearbyen	62049.0	2550	EU	"no,ru"	NOK	607072
SK	SVK	703	LO	Slovakia	Bratislava	48845.0	5447011	EU	"sk,hu"	EUR	3057568
SL	SLE	694	SL	Sierra Leone	Freetown	71740.0	7650154	AF	"en-SL,men,tem"	SLL	2403846
SM	SMR	674	SM	San Marino	San Marino	61.2	33785	EU	it-SM	EUR	3168068
SN	SEN	686	SG	Senegal	Dakar	196190.0	15854360	AF	"fr-SN,wo,fuc,mnk"	XOF	2245662
SO	SOM	706	SO	Somalia	Mogadishu	637657.0	15008154	AF	"so-SO,ar-SO,it,en-SO"	SOS	51537
SR	SUR	740	NS	Suriname	Paramaribo	163270.0	575991	SA	"nl-SR,en,srn,hns,jv"	SRD	3382998
SS	SSD	728	OD	South Sudan	Juba	644329.0	8260490	AF	en	SSP	7909807
ST	STP	678	TP	São Tomé and Príncipe	São Tomé	1001.0	197700	AF	pt-ST	STN	2410758
SV	SLV	222	ES	El Salvador	San Salvador	21040.0	6420744	NA	es-SV	USD	3585968
SY	SYR	760	SY	Syria	Damascus	185180.0	16906283	AS	"ar-SY,ku,hy,arc,fr,en"	SYP	163843
SZ	SWZ	748	WZ	Eswatini	Mbabane	17363.0	1136191	AF	"en-SZ,ss-SZ"	SZL	934841
TC	TCA	796	TK	Turks and Caicos Islands	Cockburn Town	430.0	37665	NA	en-TC	USD	3576916
TD	TCD	148	CD	Chad	N'Djamena	1284000.0	15477751	AF	"fr-TD,ar-TD,sre"	XAF	2434508
TG	TGO	768	TO	Togo	Lomé	56785.0	7889094	AF	"fr-TG,ee,hna,kbp,dag,ha"	XOF	2363686
TH	THA	764	TH	Thailand	Bangkok	514000.0	69428524	AS	"th,en"	THB	1605651
TJ	TJK	762	TI	Tajikistan	Dushanbe	143100.0	9100837	AS	"tg,ru"	TJS	1220409
TK	TKL	772	TL	Tokelau		10.0	1466	OC	"tkl,en-TK"	NZD	4031074
TL	TLS	626	TT	Timor-Leste	Dili	15007.0	1267972	OC	"tet,pt-TL,id,en"	USD	1966436
TM	TKM	795	TX	Turkmenistan	Ashgabat	488100.0	5850908	AS	"tk,ru,uz"	TMT	1218197
TN	TUN	788	TS	Tunisia	Tunis	163610.0	11565204	AF	"ar-TN,fr"	TND	2464461
TO	TON	776	TN	Tonga	Nuku'alofa	748.0	103197	OC	"to,en-TO"	TOP	4032283
TR	TUR	792	TU	Turkey	Ankara	780580.0	82319724	AS	"tr-TR,ku,diq,az,av"	TRY	298795
TT	TTO	780	TD	Trinidad and Tobago	Port of Spain	5128.0	1389858	NA	"en-TT,hns,fr,es,zh"	TTD	3573591
TV	TUV	798	TV	Tuvalu	Funafuti	26.0	11508	OC	"tvl,en,sm,gil"	AUD	2110297
TW	TWN	158	TW	Taiwan	Taipei	35980.0	23451837	AS	"zh-TW,zh,nan,hak"	TWD	1668284
TZ	TZA	834	TZ	Tanzania	Dodoma	945087.0	56318348	AF	"sw-TZ,en,ar"	TZS	149590
UA	UKR	804	UP	Ukraine	Kyiv	603700.0	44622516	EU	"uk,ru-UA,rom,pl,hu"	UAH	690791
UG	UGA	800	UG	Uganda	Kampala	236040.0	42723139	AF	"en-UG,lg,sw,ar"	UGX	226074
US	USA	840	US	United States	Washington	9629091.0	327167434	NA	"en-US,es-US,haw,fr"	USD	6252001
UY	URY	858	UY	Uruguay	Montevideo	176220.0	3449299	SA	es-UY	UYU	3439705
UZ	UZB	860	UZ	Uzbekistan	Tashkent	447400.0	32955400	AS	"uz,ru,tg"	UZS	1512440
VA	VAT	336	VT	Vatican City	Vatican City	0.44	921	EU	"la,it,fr"	EUR	3164670
VC	VCT	670	VC	St Vincent and Grenadines	Kingstown	389.0	110211	NA	"en-VC,fr"	XCD	3577815
VE	VEN	862	VE	Venezuela	Caracas	912050.0	28870195	SA	es-VE	VES	3625428
VG	VGB	92	VI	British Virgin Islands	Road Town	153.0	29802	NA	en-VG	USD	3577718
VI	VIR	850	VQ	U.S. Virgin Islands	Charlotte Amalie	352.0	106977	NA	en-VI	USD	4796775
VN	VNM	704	VM	Vietnam	Hanoi	329560.0	95540395	AS	"vi,en,fr,zh,km"	VND	1562822
VU	VUT	548	NH	Vanuatu	Port Vila	12200.0	292680	OC	"bi,en-VU,fr-VU"	VUV	2134431
WF	WLF	876	WF	Wallis and Futuna	Mata-Utu	274.0	16025	OC	"wls,fud,fr-WF"	XPF	4034749
WS	WSM	882	WS	Samoa	Apia	2944.0	196130	OC	"sm,en-WS"	WST	4034894
XK	XKX	0	KV	Kosovo	Pristina	10908.0	1845300	EU	"sq,sr"	EUR	831053
YE	YEM	887	YM	Yemen	Sanaa	527970.0	28498687	AS	ar-YE	YER	69543
YT	MYT	175	MF	Mayotte	Mamoudzou	374.0	279471	AF	fr-YT	EUR	1024031
ZA	ZAF	710	SF	South Africa	Pretoria	1219912.0	57779622	AF	"zu,xh,af,nso,en-ZA,tn,st,ts,ss,ve,nr"	ZAR	953987
ZM	ZMB	894	ZA	Zambia	Lusaka	752614.0	17351822	AF	"en-ZM,bem,loz,lun,lue,ny,toi"	ZMW	895949
ZW	ZWE	716	ZI	Zimbabwe	Harare	390580.0	14439018	AF	"en-ZW,sn,nr,nd"	ZWL	878675`;
